import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-app-polyfill/ie11';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAixLbUKzC84drMn87uhekpeqCUbqgfK_A",
  authDomain: "boekelse-verleiding.firebaseapp.com",
  databaseURL: "https://boekelse-verleiding.firebaseio.com",
  projectId: "boekelse-verleiding",
  storageBucket: "boekelse-verleiding.appspot.com",
  messagingSenderId: "885728416234",
  appId: "1:885728416234:web:0b2554db841aab0129e293",
  measurementId: "G-FB0KXWD33V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
