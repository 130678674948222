import React from 'react';
import {Link} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';

const CSS = require('./NavBar.scss');

const smoothScroll = (element, headerOffset = 45) => {
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
};

const NavBar = () => {
    return (
        <nav>
            <div className={CSS.NavBar}>
                <div className={CSS.NavBarWrapper}>
                    <ul className={CSS.NavBarList}>
                        <li className={CSS.NavBarListItem}>
                            <Link className={CSS.NavBarListItemLink} to={'/'}>Home</Link>
                        </li>
                        <li className={CSS.NavBarListItem}>
                            <HashLink
                                className={CSS.NavBarListItemLink}
                                to={'/#openingstijden'}
                                scroll={smoothScroll}
                            >
                                Openingstijden
                            </HashLink>
                        </li>
                        <li className={CSS.NavBarListItem}>
                            <Link className={CSS.NavBarListItemLink} to={'/contact/'}>Contact</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
};

export default NavBar;
