import React from 'react';
import FacebookLogo from "../Footer/flogo_RGB_HEX-512.svg";
import classnames from 'classnames';

const CSS = require('./ContactInformation.scss');

const ContactInformation = () => {
    return (
        <div className={CSS.Container}>
            <h3 className={CSS.Title}>De Boekelse Verleiding BV</h3>
            <ul className={CSS.List}>
                <li className={CSS.ListItem}>Kerkstraat 31b</li>
                <li className={CSS.ListItem}>5427 BB Boekel</li>
                <li className={CSS.ListItem}>06 10057826</li>
                <li className={CSS.ListItem}><a
                    href={'mailto:info@boekelseverleiding.nl'}>info@boekelseverleiding.nl</a></li>
            </ul>
            <a className={CSS.SocialMediaLink} href={'https://www.facebook.com/De-Boekelse-Verleiding-145929552248989/'}
               target={'_blank'}>
                <div className={CSS.SocialMediaItemContainer}>
                    <img className={classnames(CSS.FacebookLogo, CSS.SocialMediaLogo)} src={FacebookLogo}/>
                    <span>Volg ons op Facebook!</span>
                </div>
            </a>
        </div>
    )
};

export default ContactInformation;
