import React, {PureComponent} from 'react';
import Helmet from 'react-helmet';
import {PRISMIC_API_ENDPOINT} from "../utils/constants";

declare global {
    interface Window {
        prismic: any;
    }
}

class PrismicHelmet extends PureComponent {
    constructor(props) {
        super(props);
        window.prismic = {
            endpoint: PRISMIC_API_ENDPOINT,
        };
    }

    public render() {
        return (
            <Helmet>
                <script type="text/javascript" src={'https://static.cdn.prismic.io/prismic.min.js'}/>
            </Helmet>
        );
    }
}

export default PrismicHelmet;
