import React from 'react';

const CSS = require('./Marker.scss');

const Marker = ({lat, lng}) => {
    return (
        <div className={CSS.Container}>
            <span className={CSS.Text}>De Boekelse Verleiding</span>
            <div className={CSS.Dot}/>
        </div>
    )
};

export default Marker;
