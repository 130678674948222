import React, {Component} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Homepage from "./modules/homepage";
import PrismicRoutes from "./modules/prismic";
import Contact from "./modules/contact";
import OpeningHours from "./modules/openinghours";
import DefaultHelmet from "./modules/common/components/DefaultHelmet";

class App extends Component {
    render() {
        return (
            <>
                <BrowserRouter>
                    <>
                        <DefaultHelmet/>
                        <Switch>
                            <Route path={`/`} exact component={Homepage}/>
                            <Route path={`/openingstijden`} component={OpeningHours}/>
                            <Route path={`/contact`} component={Contact}/>
                            <Route path={`/prismic`} component={PrismicRoutes}/>
                        </Switch>
                    </>
                </BrowserRouter>
            </>
        );
    }
}

export default App;
