import {useState, useEffect} from 'react';
import PrismicClient from "../../prismic/utils/client";
import {OpeningHoursSlice, PhotosSlice, TextBlockSlice} from "../../prismic/utils/types";

type HomepagePrismicData = {
    documentId: string;
    openingHours: OpeningHoursSlice;
    textBlocks: TextBlockSlice[];
    photos: PhotosSlice[];
    prismicLoading?: boolean;
};


const INTIAL_STATE: HomepagePrismicData = {
    documentId: '',
    openingHours: {title: '', times: []},
    textBlocks: [],
    photos: [],
};

const usePrismicLocationPage = (id: string): HomepagePrismicData => {
    const [prismicInformation, setPrismicInformation] = useState(INTIAL_STATE);
    const [prismicLoading, setPrismicLoading] = useState(false);

    useEffect(
        () => {
            setPrismicLoading(true);
            if (prismicInformation.documentId) {
                return;
            }
            PrismicClient.getHomepageDocument().then((response) => {
                setPrismicLoading(false);
                return setPrismicInformation(response);
            });
        },
        []
    );

    return {
        ...prismicInformation,
        prismicLoading: prismicLoading
    };
};

export default usePrismicLocationPage;
