import React, {Component} from 'react';
import {Redirect, RouteComponentProps} from 'react-router-dom';
import PrismicClient from "../utils/client";

class PrismicPreview extends Component<RouteComponentProps<{ token: string }>> {
    state = {
        redirectUrl: undefined,
    };

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        PrismicClient.initPreview(params).then((url) => {
            this.setState({redirectUrl: url});
        });
    }

    public render() {
        if (this.state.redirectUrl) {
            return (
                <Redirect to={this.state.redirectUrl}/>
            );
        }

        return (
            <span>Loading Prismic Previews...</span>
        );
    }
}

export default PrismicPreview;