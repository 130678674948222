import React from 'react';
import OpeningHours from "./components/OpeningHours";
import Photos from "./components/Photos";
import PageContentBlock from "../common/PageContentBlock";
import MainLayout from "../common/layouts/MainLayout";
import usePrismicLocationPage from "../common/hooks/usePrismicHomepage";
import PrismicTextBlock from "../common/slices/TextBlock";

const Homepage = ({}) => {
    const {openingHours, textBlocks, photos, prismicLoading} = usePrismicLocationPage('homepage');
    return (
        <MainLayout>
            {!prismicLoading &&
            textBlocks.map((block, index) => <PrismicTextBlock key={`textblock-${index}`} {...block}/>)
            }
            {!prismicLoading &&
            <PageContentBlock alternateBackground fullWidth>
                <OpeningHours {...openingHours}/>
            </PageContentBlock>
            }
            {!prismicLoading &&
            <PageContentBlock>
                <Photos photos={photos}/>
            </PageContentBlock>
            }
        </MainLayout>
    );
};

export default Homepage;
