import React from 'react';
import MainLayout from "../common/layouts/MainLayout";
import ContactPage from "./components/ContactPage";

const Contact = () => {
    return (
        <MainLayout hideFooter>
            <ContactPage/>
        </MainLayout>
    )
};

export default Contact;
