import React from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

type MainLayoutProps = {
    hideFooter?: boolean;
    children: any;
}

const MainLayout = ({hideFooter, children}: MainLayoutProps) => {
    return (
        <div>
            <Header/>
            <NavBar/>
            {children}
            {!hideFooter && <Footer/>}
        </div>
    )
};

export default MainLayout;
