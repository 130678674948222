import React from 'react';
import PageContentBlock from "../../PageContentBlock";
import ContactInformation from "../ContactInformation";
import logo from '../../../../logo.png';

const CSS = require('./Footer.scss');

const Footer = ({}) => {
    return (
        <footer className={CSS.Footer}>
            <PageContentBlock>
              <div className={CSS.Content}>
                <ContactInformation/>
                <img src={logo} alt={'De Boekelse Verleiding'} className={CSS.Logo}/>
              </div>
            </PageContentBlock>
        </footer>
    )
};

export default Footer;
