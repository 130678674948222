export type TextBlockSlice = {
    title: string;
    content: any;
    image: PrismicMediaLink;
    backgroundColor: string;
    textColor: string;
};

export type OpeningHoursSlice = {
    title: string;
    times: { day: string; time: string }[];
};

export type PrismicMediaLink = {
    alt: string;
    copyright: string;
    dimensions: {
        width: number;
        height: number;
    };
    url: string;
    position: PrismicImagePosition
}

export type PrismicImage = {
    width: number;
    height: number;
    src: string;
}

export enum PrismicImagePosition {
    LEFT_TOP = 'left_bottom',
    RIGHT_BOTTOM = 'right_top',
}

export type PhotosSlice = PrismicImage;
