import React from 'react';
import PageContentBlock from "../../PageContentBlock";
import {PrismicImagePosition, TextBlockSlice} from "../../../prismic/utils/types";
import classnames from 'classnames';
import {RichText} from 'prismic-reactjs';
import { linkResolver } from '../../../prismic/utils/client';

const CSS = require('./PrismicTextBlock.scss');

const PrismicTextBlock = (content: TextBlockSlice) => {
    const mediaClassnames = classnames({
        [CSS.MediaLeftTop]: content.image.position === PrismicImagePosition.LEFT_TOP,
        [CSS.MediaRightBottom]: content.image.position === PrismicImagePosition.RIGHT_BOTTOM,
    }, CSS.Media);

    return (
        <PageContentBlock backgroundColor={content.backgroundColor} textColor={content.textColor}>
            <h2>{content.title}</h2>
            <div className={CSS.Container}>
                <div>
                  {content.content.map((contentPart) => {
                    // @ts-ignore
                    return RichText.render(contentPart.text, linkResolver);
                  })}
                </div>
                <div className={mediaClassnames}>
                    <img src={content.image.url} width={320} height={180}/>
                </div>
            </div>
        </PageContentBlock>
    )
};

export default PrismicTextBlock;
