import React, {PureComponent} from 'react';
import {Switch, Route, RouteComponentProps} from 'react-router-dom';
import PrismicPreview from "./Preview";
import PrismicHelmet from "./Helmet";

class PrismicRoutes extends PureComponent<RouteComponentProps> {
    public render() {
        const {match} = this.props;
        return (
            <div>
                <PrismicHelmet/>
                <Switch>
                    <Route path={`${match.path}/preview`} component={PrismicPreview}/>
                </Switch>
            </div>
        )
    }
}

export default PrismicRoutes;