import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../../logo.png';

const CSS = require('./Header.scss');

const Header = ({}) => {
  return (
    <Link className={CSS.HeaderLink} to={'/'}>
      <div className={CSS.Header}>
        <div className={CSS.Titles}>
          <div className={CSS.LogoAndTitle}>
            <h1 className={CSS.Title}>
              De Boekelse Verleiding
            </h1>
            <img src={logo} alt={'De Boekelse Verleiding'} className={CSS.Logo}/>
          </div>
          <h2 className={CSS.Subtitle}>Chocolaterie · IJssalon · Cadeaushop</h2>
        </div>
      </div>
    </Link>
  );
};

export default Header;
