import React from 'react';
import PageContentBlock from "../../../common/PageContentBlock";
import Map from './Map';
import ContactInformation from "../../../common/components/ContactInformation";

const CSS = require('./ContactPage.scss');

const ContactPage = () => {
    return (
        <PageContentBlock>
            <div className={CSS.Container}>
                <div className={CSS.Information}>
                    <h2>Contact</h2>
                    <p className={CSS.Text}>
                        Mocht u vragen of opmerkingen hebben, dan kunt u ons altijd contacteren! Onze contactgevens zijn
                        hieronder te vinden. Tevens kunt u deze gegevens gebruiken, mocht u willen solliciteren bij ons!
                    </p>
                    <ContactInformation/>
                </div>
                <Map/>
            </div>
        </PageContentBlock>
    )
};

export default ContactPage;
