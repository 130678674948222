import Prismic from 'prismic-javascript';
import {PRISMIC_API_ENDPOINT, PRISMIC_API_TOKEN} from "./constants";
import Parser from "./parser";

export const linkResolver = (doc) => {
    return '/' + doc.uid;
};

const cache = {};

class PrismicClient {
    static query = (query: any) => {
        return Prismic.getApi(PRISMIC_API_ENDPOINT, {accessToken: PRISMIC_API_TOKEN})
            .then((api) => {
                return api.query(query, {});
            }).then((resp) => {
                return resp.results[0];
            });
    };

    static getDocumentByType = (docType: string, forceFetch?: boolean): Promise<any> => {
        if (cache[docType] && !forceFetch) {
            return Promise.resolve(cache[docType]);
        }
        return PrismicClient.query([Prismic.Predicates.at('document.type', docType)])
            .then((result) => {
                cache[docType] = result;
                return result;
            });
    };

    static getHomepageDocument = () => {
        return PrismicClient.getDocumentByType('homepage').then((result) => {
            const textBlocks = result.data.body.filter(item => item.slice_type === 'tekstblok').map(slice => Parser.parseTextBlock(slice));
            const openingHours = result.data.body.filter(item => item.slice_type === 'openingstijden').map(slice => Parser.parseOpeningHours(slice))[0];
            const photos = result.data.body.filter(item => item.slice_type === 'foto_gallerij').map(slice => Parser.parsePhotos(slice))[0];
            return {
                textBlocks,
                openingHours,
                photos,
                documentId: result.id,
            };
        });
    };

    static initPreview = (params: URLSearchParams) => {
        return Prismic
            .getApi(PRISMIC_API_ENDPOINT, {accessToken: PRISMIC_API_TOKEN})
            .then(api => api.previewSession(params.get('token') || '', linkResolver, '/'));
    };
}

export default PrismicClient;