import {OpeningHoursSlice, PhotosSlice, TextBlockSlice} from "./types";

class Parser {
    static parseTextBlock = (slice: any): TextBlockSlice => {
        const textParts = slice.items;
        const title = slice.primary.title[0].text;
        const backgroundColor = slice.primary.background_color;
        const textColor = slice.primary.text_color;
        const image = {...slice.primary.afbeelding, position: slice.primary.image_position};
        return {title, content: textParts, textColor, backgroundColor, image};
    };

    static parseOpeningHours = (slice: any): OpeningHoursSlice => {
        const times = slice.items.reduce((acc, curr) => {
            acc.push({day: curr.day[0].text, time: curr.time[0].text});
            return acc;
        }, []);
        const title = slice.primary.title[0].text;
        return {times, title};
    };

    static parsePhotos = (slice: any): PhotosSlice => {
        const photos = slice.items.reduce((acc, curr) => {
            acc.push({src: curr.photo.url, width: 16, height: 9});
            return acc;
        }, []);
        return photos;
    };
}

export default Parser;
