import React from 'react';
import MainLayout from "../common/layouts/MainLayout";

const OpeningHours = () => {
    return (
        <MainLayout>
            Openingstijden!
        </MainLayout>
    )
};

export default OpeningHours;
