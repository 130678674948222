import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';

const getCanonicalUrl = (location) => {
    const protocol = window.location.protocol;
    const url = location.pathname;
    if (!url) {
        return `${protocol}//www.boekelseverleiding.nl`;
    }

    if (url === '' || url === '/') {
        return `${protocol}//www.boekelseverleiding.nl`;
    }

    if (url.slice(-1) === '/') {
        return `${protocol}//www.boekelseverleiding.nl${url}`;
    }

    return `${protocol}//www.boekelseverleiding.nl${url}/`;
};


const DefaultHelmet = ({ location }) => {
    const structuredDataOrg = {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "De Boekelse Verleiding",
        "url": "https://www.boekelseverleiding.nl",
        "sameAs": [
            "https://www.facebook.com/De-Boekelse-Verleiding-145929552248989/",
        ]
    };

    const structuredDataBusiness = {
        "@context": "http://schema.org",
        "@type": "LocalBusiness",
        "image": "https://www.boekelseverleiding.nl/static/media/boekelse_verleiding_vitrine.2a114b87.jpg",
        "name": "De Boekelse Verleiding",
        "url": "https://www.boekelseverleiding.nl",
        "description": "De Boekelse Verleiding is een Italiaanse ijssalon/chocolaterie uit Boekel.",
        "telephone": "+31 (0) 6 10057826",
        "address": {
            "@type": "PostalAddress",
            "addressLocality": "Boekel",
            "addressRegion": "Noord-Brabant",
            "streetAddress": "Kerkstraat 31b",
            "postalCode": "5427 BB"
        },
    };
    return (
        <Helmet>
            <title>De Boekelse Verleiding</title>
            <script type="application/ld+json">
                {JSON.stringify(structuredDataOrg)}
            </script>
            <script type="application/ld+json">
                {JSON.stringify(structuredDataBusiness)}
            </script>
            <link rel="canonical" href={getCanonicalUrl(location)}/>
        </Helmet>
    )
};

export default withRouter(DefaultHelmet);
