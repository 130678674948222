import React from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from "./Marker";

const DEFAULT_ZOOM = 19;

const DEFAULT_CENTER = {lat: 51.6036053, lng: 5.6736917};

const CSS = require('./Map.scss');

const Map = () => {
    return (
        <div className={CSS.Container}>
            <GoogleMapReact
                yesIWantToUseGoogleMapApiInternals
                bootstrapURLKeys={{key: 'AIzaSyCFscukjXmzjczn0t8qBLNFF5ws0xNLy8U'}}
                defaultZoom={DEFAULT_ZOOM}
                defaultCenter={DEFAULT_CENTER}
            >
                <Marker lat={51.6037} lng={5.673678} />
            </GoogleMapReact>
        </div>

    );
};

export default Map;
