import React from 'react';
import {OpeningHoursSlice} from "../../../prismic/utils/types";

const CSS = require('./OpeningHours.scss');

const fullDayToTwoLetter = {
    'Maandag': 'Mo',
    'Dinsdag': 'Tu',
    'Woensdag': 'We',
    'Donderdag': 'Th',
    'Vrijdag': 'Fr',
    'Zaterdag': 'Sa',
    'Zondag': 'Su'
};

const OpeningHours = ({title, times}: any) => {
    if (!times || times.length < 1) {
        return null;
    }
    return (
        <div className={CSS.OpeningHours} id={'openingstijden'}>
            <h2 className={CSS.Title}>Openingstijden</h2>
            <ul className={CSS.List}>
                {
                    times.map(({day, time}) => (
                        <li key={`${day}-${time}`} className={CSS.ListItem}>
                            <meta itemProp="openingHours" content={`${fullDayToTwoLetter[day]} ${time.replace(/\s+/g, '')}`}/>
                            <div className={CSS.Day}>{day}</div>
                            <div className={CSS.Time}>{time}</div>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
};

export default OpeningHours;
